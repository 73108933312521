@font-face {
  font-family: "Cal Sans";
  src: url("/assets/easyoffice/fonts/CalSans.woff2") format("woff2");
}
:root {
  --easyoffice-header-height: 42px;
  --easyoffice-expanded-height: 84vh;
  --easyoffice-content-height: calc(var(--easyoffice-expanded-height) - var(--easyoffice-header-height));
  --easyoffice-content-width: 400px;
}

.cal-sans {
  font-family: "Cal Sans", "Inter", sans-serif;
}

.skeleton {
  background-color: var(--skeleton-bg);
  animation: 2s breathe infinite;
}

@keyframes breathe {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.break-all {
  word-break: break-all;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}